import {Component} from 'nuxt-property-decorator';
// import LoginSignUpComponent from '@/components/shared/client-layout/loginsignup/loginsignup.component';
import {AccountsApi, SessionModel, UserSession, UsersModel, UsersService} from '@/sdk';
import VueWrapper from '~/classes/vue.wrapper';
import {CoreService} from '~/sdk/services/core.service';
import ClientDrawerComponent from '../client-drawer/client-drawer.component';
import Vuetify from 'vuetify/lib';

@Component({
    components: {
        ClientDrawerComponent,
        // LoginSignUpComponent,
    },
})
export default class ClientAppBarComponent extends VueWrapper {
    public isShowHeaderMenu: boolean = false;
    public isAdsListOpen: boolean = false;
    public isResrcListOpen: boolean = false;
    public hideHeaderMenu() {
        this.isShowHeaderMenu = false;
        this.isAdsListOpen = false;
        this.isResrcListOpen = false;
    }
    public ads: Array<{title: string; link: string}> = [
        {title: 'PARKS FOR SALE ADS', link: 'park-for-sale-ads'},
        {title: 'CLASSIFIED ADS', link: 'classified-ads'},
        {title: 'PROFESSIONALS', link: 'professionals'},
    ];

    get activeAd() {
        return this.ads.map(x => x.link).includes(this.$route.name!);
    }
    public resources: Array<{title: string; link: string; openNewWindow?: boolean}> = [
        {title: 'SCHEDULE DEMO', link: 'schedule-support'},
        {title: 'BLOG ARTICLES', link: 'blog-articles'},
        {title: 'HELP CENTER', link: 'https://help.mhparks.com', openNewWindow: true},
        {title: 'OUR TEAM', link: 'our-team'},
        {title: 'CONTACT US', link: 'contact-us'},
        // {title: 'CASE STUDIES', link: 'case-studies'},
        // {title: 'VIDEO TIPS', link: 'video-tips'},
        // {title: 'RELEASE NOTES', link: 'https://help.mhparks.com/collection/83-release-notes', openNewWindow: true},
    ];

    public redirect(res: {title: string; link: string; openNewWindow?: boolean}) {
        if (res.openNewWindow) {
            window.open(res.link, '_blank');
        } else {
            this.$router.push({name: res.link});
        }
    }
    get activeResources() {
        return this.resources.map(x => x.link).includes(this.$route.name!);
    }
    get adsRoutes() {
        return this.ads.map(x => x.link).includes(this.$route.name!);
    }
    get resourcesRoutes() {
        return this.resources.map(x => x.link).includes(this.$route.name!);
    }
    public Links = [
        {title: 'HOME', name: 'index'},
        {title: 'SOLUTIONS', name: 'solutions'},
        {title: 'PRICING', name: 'pricing'},
        // {title: 'Blog', name: 'blog'},
        // {title: 'Contact', name: 'contact-us'},
    ];
    public CoreSrv = new CoreService();
    public User = new UsersModel();
    public UserSrv = new UsersService();
    public whyMhParksItems = [
        {
            title: 'Evolve with MHParks',
            link: 'How MH Park Works',
            icon: 'mdi-lightbulb-outline',
        },
        {
            title: 'Take the MHParks Tour',
            link: 'Product Tour',
            icon: 'mdi-cube-outline',
        },

        {
            title: 'Frequently Asked Questions',
            link: 'FAQs',
            icon: 'mdi-frequently-asked-questions',
        },
        {
            title: 'Learn More About MHParks',
            link: 'Learn More',
            icon: 'mdi-help-circle-outline',
        },
        {
            title: 'See How MHParks Rates',
            link: 'Review',
            icon: 'mdi-star-outline',
        },
    ];
    public additionalTools = [
        {
            title: 'Park Sites',
            link: 'park-sites-solution',
            icon: 'mdi-tree-outline',
        },
        {
            title: 'Advertise',
            link: 'advertising',
            icon: 'mdi-bullhorn-outline',
        },
        {
            title: 'Sold Parks',
            link: 'sold-parks-solution',
            icon: 'mdi-cube-outline',
        },

        {
            title: 'My Parks',
            link: '/solutions/my-parks-solution',
            icon: 'mdi-map-marker-outline',
        },
        {
            title: 'Contacts',
            link: 'mobile-home-park-industry-contacts',
            icon: 'mdi-account-group-outline',
        },
    ];

    public Session = new SessionModel();
    public UserMenu = false;
    public UserMenuMobile = false;
    public myload = true;
    public whyMhParks = false;
    public featureMenus = false;

    public router() {
        if (this.$route.name === 'index') {
            return false;
        } else return this.$router.push({name: 'index'});
    }

    public menuItems = [
        {title: 'My profile', icon: 'mdi-account-circle', name: 'Profile'},

        {
            title: 'Logout',
            icon: 'mdi-logout',
            name: 'Home',
            method: this.UserLogout,
        },
    ];

    public created() {
        this.AddSubscription$ = new UserSession()._session.subscribe(session => {
            this.Session = session ?? new SessionModel();
        });

        this.AddSubscription$ = this.UserSrv.loggedInUser$.subscribe(Res => {
            this.User = Res;
        });
        // if (this.UserSession.isUserAuthenticated) {
        //     this.UserSrv.getById(new UserSession().Session?.UserId!);
        // }

        this.CoreSrv.dialog.loginsignupPage = false;
    }
    get num() {
        return this.$vuetify.breakpoint.width <= 1169;
    }
    public UserLogout() {
        new AccountsApi().Logout();
        new UserSession().clear();
        // this.UserMenu = false;
        // this.UserMenuMobile = false;
    }
    get ipadPro() {
        if (window.innerWidth >= 1264) {
            return 'flex-wrap px-16';
        } else if (window.innerWidth <= 990) {
            return 'flex-wrap';
        } else return null;
    }

    get SessionExist() {
        if (this.Session.JwtToken) {
            console.log(true);
            return true;
        } else {
            console.log(false);
            return false;
        }
    }

    public blogRouteUpdate() {
        if (this.$route.name === 'blog') {
            this.$router.push({name: 'blog'});
        } else {
            this.$router.push({name: 'blog'});
        }
    }
}
